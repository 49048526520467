import "semantic-ui-less/semantic.less";
import "./theme/kendo.scss";
import "./theme/faceted-filter.scss";
import "react-toastify/dist/ReactToastify.css";
import { Grid, Segment } from "semantic-ui-react";
import React, { useState } from "react";

import AppRoutes from "./AppRoutes";
import AuthError from "./components/AuthError";
import Footer from "./components/Footer";
import Helmet from "react-helmet";
import History from "./services/History";
import MarketingBar from "./components/MarketingBar";
import Navbar from "./components/Navbar";
import { Router } from "react-router";
import TermsAndConditions from "./components/TermsAndConditions";
import { ToastContainer } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

const App: React.FC = () => {
    const { isLoading, error, isAuthenticated } = useAuth0();
    const [matches] = useState(window.matchMedia("(min-width: 768px)").matches);

    if (isLoading) {
        return <p>Please wait...</p>;
    }

    if (error) {
        return <AuthError error={error} />;
    }

    return (
        <Router history={History}>
            <Helmet>
                <title>LITCO Portal</title>
            </Helmet>
            <Grid padded="horizontally">
                <Grid.Row centered>
                    <Grid.Column tablet={16} largeScreen={14} widescreen={12}>
                        <Navbar />
                        {matches && <MarketingBar />}
                        <Segment style={{ minHeight: "400px" }}>
                            <ToastContainer />

                            <AppRoutes />
                        </Segment>
                        {isAuthenticated && <TermsAndConditions />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Footer />
        </Router>
    );
};

export default App;
