import { Button, Grid, Icon, Message } from "semantic-ui-react";

import React from "react";
import config from "../config";
import { useAuth0 } from "@auth0/auth0-react";

interface AuthErrorProps {
    error: Error;
}

const AuthError: React.FC<AuthErrorProps> = ({ error }) => {
    const { loginWithRedirect, logout } = useAuth0();

    return (
        <Grid centered padded="vertically">
            <Grid.Column width="6" textAlign="center">
                <Message icon error>
                    <Icon name="exclamation triangle" />
                    <Message.Content>
                        <Message.Header>Authentication Error</Message.Header>
                        <p>{error.message}</p>
                        <p>
                            For more information, contact our support team{" "}
                            <a href={`mailto:${config.supportEmailAddress}`}>{config.supportEmailAddress}</a> or{" "}
                            <a href={`tel:${config.supportPhoneNumber}`}>{config.supportPhoneNumber}</a>.
                        </p>
                        <Button type="button" color="grey" content="Retry" onClick={loginWithRedirect} />
                        <br />
                        <br />
                        <Button type="button" color="grey" content="Return to Login" onClick={() => logout()} />
                    </Message.Content>
                </Message>
            </Grid.Column>
        </Grid>
    );
};
export default AuthError;
