import { Icon, Menu } from "semantic-ui-react";

import React from "react";
import config from "../config";

const Footer: React.FC = () => {
    return (
        <>
            <Menu
                tabular
                stackable
                style={{
                    position: "bottom",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    marginTop: "-15px",
                    backgroundColor: "white",
                    border: "none"
                }}
            >
                <Menu.Item style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <Icon style={{ color: "green", marginBottom: "2px" }} centered="true" color="green" name="leaf" size="big" />
                    <p style={{ fontSize: "14px", color: "green" }}>THANK YOU FOR HELPING LITCO GO GREEN!</p>
                </Menu.Item>
            </Menu>
            <Menu
                tabular
                inverted
                stackable
                style={{
                    position: "bottom",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    marginTop: "-15px",
                    justifyContent: "center"
                }}
            >
                <Menu.Item>
                    <div>&copy; Copyright Life Insurance Trust Company {new Date().getFullYear()} | All Rights Reserved</div>
                </Menu.Item>
                <Menu.Item>
                    <a href={config.termsAndConditionsUrl} target="_blank" rel="noreferrer" style={{ color: "white", lineHeight: 1.25 }}>
                        Online Access Agreement
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a href={config.privacyPolicyUrl} target="_blank" rel="noreferrer" style={{ color: "white", lineHeight: 1.25 }}>
                        Privacy Policy
                    </a>
                </Menu.Item>
            </Menu>
        </>
    );
};

export default Footer;
