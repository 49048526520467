import { Menu } from "semantic-ui-react";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const MarketingBar: React.FC = () => {
    const { user } = useAuth0();

    return (
        <>
            {user && (
                <Menu style={{ marginBottom: "-15px" }} stackable tabular>
                    <Menu.Menu position="right">
                        <Menu.Item
                            href="https://lifeinsurancetrustco.com/grantors-and-beneficiaries"
                            content="Grantor Beneficiary Resources"
                            target="_blank"
                            color="blue"
                        />
                        <Menu.Item href="https://lifeinsurancetrustco.com/blog" target="_blank" content="Blog" color="blue" />
                    </Menu.Menu>
                </Menu>
            )}
        </>
    );
};

export default MarketingBar;
