import { Form, Message, Modal } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import config from "../config";
import useSproc from "../hooks/useSproc";

const TermsAndConditions: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [termsError, setTermsError] = useState<string>();
    const [termsSuccess, setTermsSuccess] = useState<boolean>(false);
    const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const sproc = useSproc();

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                await sproc("CheckTermsAndConditions");
                setTermsSuccess(true);
            } catch (err) {
                console.log(err);
                if (err instanceof Error) {
                    setModalOpen(true);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [sproc]);

    const onSubmitTerms = async () => {
        try {
            setLoading(true);

            await sproc("InsertTermsAndConditions", { ip: "IPADDRESS" });
            setTermsSuccess(true);
            setModalOpen(false);
        } catch (err) {
            console.log(err);
            if (err instanceof Error) {
                setTermsError("This email does not match one in our system.");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={modalOpen}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            closeOnDocumentClick={false}
            closeOnPortalMouseLeave={false}
            closeOnTriggerBlur={false}
            closeOnTriggerClick={false}
            closeOnTriggerMouseLeave={false}
        >
            <Modal.Header content="Updates" />
            <Modal.Content centered>
                <Form onSubmit={onSubmitTerms} error={!!termsError} success={termsSuccess} loading={loading}>
                    <Message error content={termsError} icon="exclamation triangle" onDismiss={() => setTermsError(undefined)} />
                    <Message success content={<>Online Access Agreement was saved!</>} icon="check" />

                    <Message
                        content={
                            <>
                                The online access agreement and/or the privacy policy have been updated. Complete the following to continue.
                            </>
                        }
                        icon="info circle"
                        info
                    />
                    <div style={{ textAlign: "center", marginTop: "28px" }}>
                        <Form.Checkbox
                            checked={agreeToTerms}
                            onChange={(e, { checked }) => setAgreeToTerms(!!checked)}
                            styles={{ marginBottom: "20px" }}
                            label={
                                <label>
                                    I have read and agree to the{" "}
                                    <a href={config.termsAndConditionsUrl} target="_blank" rel="noreferrer">
                                        Online Access Agreement.
                                    </a>{" "}
                                    I acknowledge the{" "}
                                    <a href={config.privacyPolicyUrl} target="_blank" rel="noreferrer">
                                        Privacy Policy.
                                    </a>
                                </label>
                            }
                        />
                    </div>
                    <div style={{ textAlign: "center", marginTop: "28px" }}>
                        <Form.Button
                            primary
                            type="submit"
                            icon="check"
                            content="I Agree"
                            styles={{ marginTop: "100px" }}
                            disabled={!agreeToTerms || termsSuccess || !!termsError}
                        />
                    </div>
                </Form>
            </Modal.Content>
        </Modal>
    );
};

export default TermsAndConditions;
