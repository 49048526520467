import { Dropdown, Icon, Image, Menu, Message } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { InternalUser } from "../types";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";
import { useAuth0 } from "@auth0/auth0-react";
import useSproc from "../hooks/useSproc";
import useTrustDataService from "../hooks/useTrustDataService";

const Navbar: React.FC = () => {
    const { user } = useAuth0();
    const { logout } = useAuth0();
    const [userRoles, setUserRoles] = useState<{ ContactType: string }[]>();
    const [shadowUser, setShadowUser] = useState<InternalUser>();
    const { isAuthenticated } = useAuth0();
    const { isInternalUser } = useTrustDataService();
    const [matches] = useState(window.matchMedia("(min-width: 768px)").matches);

    const sproc = useSproc();
    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                try {
                    const roleList = await sproc("GetUserRoles");
                    setUserRoles(roleList);
                    const data = await isInternalUser();
                    setShadowUser(data);
                } catch (err) {
                    console.log(err);
                }
            }
        })();
    }, [isAuthenticated, isInternalUser, sproc]);

    return (
        <>
            {matches && (
                <>
                    <Menu stackable tabular>
                        <Menu.Item style={{ marginLeft: "-30px" }}>
                            <Image size="medium" src={logo} as={Link} to="/trust" />
                        </Menu.Item>

                        <Menu.Menu position="right">
                            <Menu.Item as={Link} to="/support" content="Support" icon="help circle" color="blue" />
                            {user && (
                                <>
                                    {!!userRoles &&
                                        userRoles?.some(
                                            (type) =>
                                                type.ContactType === "Grantor" ||
                                                type.ContactType === "Beneficiary" ||
                                                type.ContactType === "Conservator" ||
                                                type.ContactType === "Guardian" ||
                                                type.ContactType === "Power of Attorney"
                                        ) && (
                                            <Menu.Item
                                                as={Link}
                                                to="/update-contacts"
                                                content="Update Contact Details"
                                                icon="edit"
                                                color="blue"
                                            />
                                        )}
                                    <Menu.Item
                                        as={Link}
                                        to="/communication"
                                        content="Communication Preferences"
                                        icon="envelope"
                                        color="blue"
                                    />
                                    <Menu.Item as={Link} to="/trust" content="Home" icon="home" color="blue" />
                                    <Menu.Item icon="lock" color="blue" onClick={() => logout()} content="Logout" />
                                </>
                            )}
                            {!user && <Menu.Item as={Link} to="/trust" content="Log In" icon="sign in" color="blue" />}
                        </Menu.Menu>
                    </Menu>
                    {shadowUser && shadowUser.IsInternal && (
                        <Message attached negative style={{ marginTop: "-14px" }}>
                            <Message.Header>
                                <Icon name="user secret" />
                                Impersonating {shadowUser.FullName} ({shadowUser.ContactID}), {shadowUser.EmailAddress}
                            </Message.Header>
                        </Message>
                    )}
                </>
            )}

            {!matches && (
                <>
                    <Menu tabular fitted pointing>
                        <Menu.Item style={{ marginLeft: "-30px" }}>
                            <Image size={matches ? "medium" : "small"} src={logo} as={Link} to="/trust" />
                        </Menu.Item>

                        <Menu.Menu position="right">
                            {user && (
                                <>
                                    <>
                                        <Dropdown direction="left" item icon="bars">
                                            <Dropdown.Menu>
                                                <Menu.Item as={Link} to="/trust" content="Home" icon="home" color="blue" />

                                                {!!userRoles &&
                                                    userRoles?.some(
                                                        (type) =>
                                                            type.ContactType === "Grantor" ||
                                                            type.ContactType === "Beneficiary" ||
                                                            type.ContactType === "Conservator" ||
                                                            type.ContactType === "Guardian" ||
                                                            type.ContactType === "Power of Attorney"
                                                    ) && (
                                                        <Menu.Item
                                                            as={Link}
                                                            to="/update-contacts"
                                                            content="Update Contact Details"
                                                            icon="edit"
                                                            color="blue"
                                                        />
                                                    )}
                                                <Menu.Item
                                                    as={Link}
                                                    to="/communication"
                                                    content="Communication Preferences"
                                                    icon="envelope"
                                                    color="blue"
                                                />

                                                <Menu.Item as={Link} to="/support" content="Support" icon="help circle" color="blue" />

                                                <Dropdown.Divider />
                                                <Menu.Item
                                                    href="https://lifeinsurancetrustco.com/grantors-and-beneficiaries"
                                                    content="Grantor Beneficiary Resources"
                                                    target="_blank"
                                                    color="blue"
                                                />
                                                <Menu.Item
                                                    href="https://lifeinsurancetrustco.com/blog"
                                                    target="_blank"
                                                    content="Blog"
                                                    color="blue"
                                                />
                                                <Dropdown.Divider />
                                                <Menu.Item icon="lock" color="blue" onClick={() => logout()} content="Logout" />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </>
                                </>
                            )}
                            {!user && (
                                <>
                                    {" "}
                                    <Menu.Item as={Link} to="/support" content="Support" icon="help circle" color="blue" />
                                    <Menu.Item as={Link} to="/trust" content="Log In" icon="sign in" color="blue" />
                                </>
                            )}
                        </Menu.Menu>
                    </Menu>
                    {shadowUser && shadowUser.IsInternal && (
                        <Message attached negative style={{ marginTop: "-14px" }}>
                            <Message.Header>
                                <Icon name="user secret" />
                                Impersonating {shadowUser.FullName} ({shadowUser.ContactID}), {shadowUser.EmailAddress}
                            </Message.Header>
                        </Message>
                    )}
                </>
            )}
            {matches}
        </>
    );
};

export default Navbar;
