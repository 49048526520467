import {
    CashBalance,
    ConfirmContactsNotification,
    Contact,
    EditContactRow,
    GiftNotice,
    GiftNoticeAdminFee,
    GiftNoticePremium,
    InnovestData,
    InternalUser,
    NotificationRow,
    Policy,
    StateRef,
    Trust,
    TrustDocument,
    TrustRow,
    UserPhone
} from "../types";

import axios from "axios";
import config from "../config";
import { parseDates } from "../services/Library";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import useSproc from "./useSproc";

interface UseTrustDataServiceOutput {
    checkUserCommunicationPreferences: () => Promise<void>;
    getIpAddress: () => Promise<string>;
    getNotifications: () => Promise<NotificationRow[]>;
    getTrusts: () => Promise<TrustRow[]>;
    getTrust: (trustID: number) => Promise<Trust>;
    getTrustPolicies: (trustID: number) => Promise<Policy[]>;
    getTrustContacts: (trustID: number) => Promise<Contact[]>;
    getTrustDocuments: (trustID: number) => Promise<TrustDocument[]>;
    getTrustTransactions: (trustAccountNumber: string) => Promise<InnovestData>;
    getUserCellPhone: (contactID: number) => Promise<UserPhone[]>;
    getContacts: () => Promise<EditContactRow[]>;
    getStates: () => Promise<StateRef[]>;
    getConfirmContactDetails: () => Promise<ConfirmContactsNotification[]>;
    getGiftAdminFees: (trustID: number, PolicyGiftAndFeeRequestID: number) => Promise<GiftNoticeAdminFee[]>;
    getGiftNoticePremiums: (trustID: number, PolicyGiftAndFeeRequestID: number) => Promise<GiftNoticePremium[]>;
    getGiftNotice: (trustID: number) => Promise<GiftNotice[]>;
    getPaymentsDue: () => Promise<{ TrustID: number; TrustName: string; PaymentEnabled: boolean }[]>;
    isInternalUser: () => Promise<InternalUser>;
}

const useTrustDataService = (): UseTrustDataServiceOutput => {
    const sproc = useSproc();
    const { getAccessTokenSilently } = useAuth0();

    const checkUserCommunicationPreferences = useCallback(async (): Promise<void> => {
        await sproc("CheckUserCommunicationPreferences");
        return;
    }, [sproc]);

    const getTrusts = useCallback(async (): Promise<TrustRow[]> => {
        const trusts = await sproc("GetTrusts");
        return trusts;
    }, [sproc]);

    const getTrust = useCallback(
        async (trustID: number): Promise<Trust> => {
            const [trust]: Trust[] = await sproc("GetTrustDetails", { trustID });
            return trust;
        },
        [sproc]
    );

    const getTrustPolicies = useCallback(
        async (trustID: number): Promise<Policy[]> => {
            const policies: Policy[] = await sproc("GetPoliciesByTrustID", { trustID });
            return policies;
        },
        [sproc]
    );

    const getTrustContacts = useCallback(
        async (trustID: number): Promise<Contact[]> => {
            const contacts: Contact[] = await sproc("GetContactInformation", { trustID });
            return contacts;
        },
        [sproc]
    );

    const getTrustDocuments = useCallback(
        async (trustID: number): Promise<TrustDocument[]> => {
            const documents: TrustDocument[] = await sproc("GetTrustDocuments", { trustID });
            return parseDates(documents, ["DocumentDate"]);
        },
        [sproc]
    );

    const getTrustTransactions = useCallback(
        async (trustAccountNumber: string): Promise<InnovestData> => {
            const url = `${config.innovestApiUrl}/client/cashtransaction`;

            const token = await getAccessTokenSilently();
            const { data: transactions } = await axios.get<InnovestData>(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    TrustAccountNumber: trustAccountNumber
                }
            });
            transactions.CashTransactions = parseDates(transactions.CashTransactions, ["SettleDate"]);
            transactions.CashBalance.map((balance: CashBalance) => ({
                ...balance,
                ReportingValue: Number(balance.ReportingValue)
            }));
            return transactions;
        },
        [getAccessTokenSilently]
    );

    const getUserCellPhone = useCallback(
        async (contactID: number): Promise<UserPhone[]> => {
            const phones: UserPhone[] = await sproc("GetUserCellPhone", { contactID });
            return phones;
        },
        [sproc]
    );

    const getIpAddress = useCallback(async (): Promise<string> => {
        const url = `${config.authenticateApiUrl}/ip`;
        const { data } = await axios.get<{ ip: string }>(url);
        return data.ip;
    }, []);

    const getNotifications = useCallback(async (): Promise<NotificationRow[]> => {
        const notifications = await sproc("GetUserNotifications");
        return parseDates(notifications, ["NotificationDate"]);
    }, [sproc]);

    const getContacts = useCallback(async (): Promise<EditContactRow[]> => {
        const contacts: EditContactRow[] = await sproc("GetContactInformationToUpdate");
        return contacts;
    }, [sproc]);

    const getStates = useCallback(async (): Promise<StateRef[]> => {
        const data = await sproc<StateRef>("GetListItemsByListShortName", { ShortName: "State", Active: true });
        return data;
    }, [sproc]);

    const getConfirmContactDetails = useCallback(async (): Promise<ConfirmContactsNotification[]> => {
        const needConfirm: ConfirmContactsNotification[] = await sproc("GetConfirmContactDetails");
        return needConfirm;
    }, [sproc]);

    const getGiftAdminFees = useCallback(
        async (trustID: number, PolicyGiftAndFeeRequestID: number): Promise<GiftNoticeAdminFee[]> => {
            const giftNotices: GiftNoticeAdminFee[] = await sproc("GetOutstandingGiftAndFeeRequestAdminFee", {
                trustID,
                PolicyGiftAndFeeRequestID
            });
            return parseDates(giftNotices, ["FeePeriodStart", "FeePeriodEnd"], "TRIM Z");
        },
        [sproc]
    );

    const getGiftNoticePremiums = useCallback(
        async (trustID: number, PolicyGiftAndFeeRequestID: number): Promise<GiftNoticePremium[]> => {
            const giftNotices: GiftNoticePremium[] = await sproc("GetOutstandingGiftAndFeeRequestPremium", {
                trustID,
                PolicyGiftAndFeeRequestID
            });
            return giftNotices;
        },
        [sproc]
    );

    const getGiftNotice = useCallback(
        async (trustID: number): Promise<GiftNotice[]> => {
            const giftNotices: GiftNotice[] = await sproc("GetOutstandingGiftAndFeeRequest", { trustID });
            return giftNotices;
        },
        [sproc]
    );

    const getPaymentsDue = useCallback(async (): Promise<{ TrustID: number; TrustName: string; PaymentEnabled: boolean }[]> => {
        const data = await sproc<{ TrustID: number; TrustName: string; PaymentEnabled: boolean }>("GetPaymentsDue");
        return data;
    }, [sproc]);

    const isInternalUser = useCallback(async (): Promise<InternalUser> => {
        const [data] = await sproc("IsInternalUser");
        return data;
    }, [sproc]);

    return {
        checkUserCommunicationPreferences,
        getIpAddress,
        getNotifications,
        getTrusts,
        getTrust,
        getTrustPolicies,
        getTrustContacts,
        getTrustDocuments,
        getTrustTransactions,
        getUserCellPhone,
        getContacts,
        getStates,
        getConfirmContactDetails,
        getGiftAdminFees,
        getGiftNoticePremiums,
        getGiftNotice,
        getPaymentsDue,
        isInternalUser
    };
};

export default useTrustDataService;
